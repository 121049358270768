import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// import * as styles from "./index.module.scss"
import HeroImage from "../../assets/images/B2B-header-tall.svg"

const Banner = ({ title, slogan, image }) => {
  const img = getImage(image)
  return (
    <div
      style={{
        backgroundImage: `url(${HeroImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
      className="py-20 lg:py-10"
    >
      <div className="px-4 md:px-8 xl:px-0 mx-auto max-w-6xl flex flex-col lg:flex-row items-center gap-y-4">
        <div className={`w-full ${image ? "lg:w-3/5" : "lg:w-4/5"}`}>
          {title && (
            <h1 className="text-center lg:text-left text-white uppercase mb-3">
              {title}
            </h1>
          )}
          {slogan && (
            <h2
              className={`h2-light text-center lg:text-left text-white px-2 lg:px-0 lg:w-10/12`}
            >
              {slogan}
            </h2>
          )}
        </div>
        {image && (
          <div className="w-full lg:w-2/5 flex justify-center">
            <GatsbyImage loading="eager" image={img} alt={image.title} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Banner
