import React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import About from "../containers/Partners/about"
import Benefits from "../containers/Partners/benefits"
import Quote from "../containers/Partners/quoteView/index.jsx"
import JoinBanner from "../containers/Partners/joinBanner/index.jsx"
import FeaturedPartners from "../containers/Partners/featuredPartners/index.jsx"
import ProgramBenefits from "../containers/Partners/programBenefits"
import CaseStudies from "../components/caseStudies"

const PartnersPage = ({ data }) => {
  const {
    ogTitle,
    ogDescription,
    bannerTitle,
    bannerSubtitle,
    bannerImage,
    becomePartnerBannerTitle,
    benefitsTitle,
    benefitsDescription,
    benefitsList,
    featuresTitle,
    featuresList,
    quote,
    featuredPartnersTitle,
    featuredPartnersImage,
    featuredPartnersList,
    caseStudiesTitle,
    caseStudiesList,
    partnerBenefitsTitle,
    partnerBenefitsList,
  } = data.partners

  const AboutSection = {
    heading: benefitsTitle,
    details: benefitsDescription,
    listItems: benefitsList,
  }
  const BenefitsSection = { featuresTitle, featuresList }
  const FeaturedPartner = {
    featuredPartnersTitle,
    featuredPartnersImage,
    featuredPartnersList,
  }
  const CaseStudiesData = {
    caseStudiesTitle,
    caseStudiesList,
  }
  const ProgramBenefitsSection = { partnerBenefitsTitle, partnerBenefitsList }

  return (
    <Layout>
      <Seo title={ogTitle || "Partners"} description={ogDescription} />
      <Banner title={bannerTitle} slogan={bannerSubtitle} image={bannerImage} />
      <JoinBanner data={becomePartnerBannerTitle} />
      <About data={AboutSection} />
      <Benefits data={BenefitsSection} />
      <Quote data={quote} />
      <JoinBanner data={becomePartnerBannerTitle} />
      <FeaturedPartners data={FeaturedPartner} />
      <CaseStudies data={CaseStudiesData} altBack />
      <JoinBanner data={becomePartnerBannerTitle} />
      <ProgramBenefits data={ProgramBenefitsSection} />
    </Layout>
  )
}
export const query = graphql`
  query PartnersQuery {
    partners: contentfulPartnerPage {
      ogTitle
      ogDescription
      bannerTitle
      bannerSubtitle
      bannerImage {
        title
        gatsbyImageData(width: 400, quality: 100, placeholder: BLURRED)
      }
      becomePartnerBannerTitle
      benefitsTitle
      benefitsDescription
      benefitsList
      featuresTitle
      featuresList {
        id
        title
        description {
          description
        }
        image {
          title
          file {
            url
          }
        }
      }
      quote {
        author
        remarks {
          remarks
        }
        logo {
          title
          file {
            url
          }
        }
      }
      featuredPartnersTitle
      featuredPartnersImage {
        title
        gatsbyImageData(width: 382, quality: 100, placeholder: BLURRED)
      }
      featuredPartnersList {
        id
        image {
          title
          gatsbyImageData(width: 222, quality: 100, placeholder: BLURRED)
        }
        website
        websiteUrl
      }
      caseStudiesTitle
      caseStudiesList {
        id
        link
        slogan
        logo {
          title
          file {
            url
          }
        }
        backgroundImage {
          title
          gatsbyImageData(width: 650, placeholder: BLURRED)
        }
      }
      partnerBenefitsTitle
      partnerBenefitsList {
        id
        title
        image {
          title
          gatsbyImageData(width: 150, quality: 100, placeholder: BLURRED)
        }
      }
    }
  }
`

export default PartnersPage
