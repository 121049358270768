import React from "react"

import * as styles from "./index.module.scss"

const Benefits = ({ data: { featuresTitle, featuresList } }) => (
  <div className="max-w-6xl mx-auto mt-2 lg:mt-12 px-6 xl:px-0 xl:mb-16">
    <h3 className="mb-8 text-center lg:text-left">{featuresTitle}</h3>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 md:gap-y-12 md:gap-x-12">
      {featuresList.map(({ id, title, description, image }) => (
        <div key={id} className="flex flex-col items-center lg:items-start">
          <img
            src={image.file.url}
            alt={image.title}
            loading="lazy"
            className={`${styles.benifitImage}`}
          />
          <h4 className="my-3 text-center lg:text-left">{title}</h4>
          {description && (
            <p className="text-center lg:text-left">
              {description.description}
            </p>
          )}
        </div>
      ))}
    </div>
  </div>
)

export default Benefits
