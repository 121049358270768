import React from "react"
import { usePartnerActions } from "../../../context/popupContext"

import * as styles from "./index.module.scss"

const JoinBanner = ({ data }) => {
  const { email, handleEmailInput, openPartnerForm } = usePartnerActions()

  return (
    <div
      className={`${styles.bePartnerContainer} py-7 md:py-10 px-7 lg:px-4 xl:px-0`}
    >
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row text-center">
        <div className="flex-1">
          <h2 className="text-white text-center lg:text-left px-8 lg:px-0 mb-3 lg:mb-0">
            {data}
          </h2>
        </div>
        <div>
          <form onSubmit={openPartnerForm}>
            <div
              className={`${styles.joinContainer} flex flex-col lg:flex-row items-center`}
            >
              <input
                className={`${styles.emailField} mb-4 lg:mb-0`}
                type="email"
                name="email"
                placeholder="Enter Your Email"
                required
                value={email}
                onChange={handleEmailInput}
              />
              <button type="submit" className={`${styles.joinButton} bggh`}>
                <div className="z-10 w-full">Become A Partner</div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default JoinBanner
