import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProgramBenefits = ({
  data: { partnerBenefitsTitle, partnerBenefitsList },
}) => (
  <div>
    <div className="max-w-6xl mx-auto pt-6 lg:pt-20 pb-12 lg:pb-40 px-4 xl:px-0">
      <h3 className="text-center lg:text-left mb-4 lg:mb-12">
        {partnerBenefitsTitle}
      </h3>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-2 gap-y-8 lg:gap-y-20">
        {partnerBenefitsList.map(({ id, title, image }) => {
          const img = getImage(image)
          return (
            <div key={id} className="flex items-center px-3 lg:px-0">
              <GatsbyImage
                image={img}
                alt={image.title}
                loading="lazy"
                className="mx-auto"
              />
              <h4 className="flex-1 lg:ml-2">{title}</h4>
            </div>
          )
        })}
      </div>
    </div>
  </div>
)

export default ProgramBenefits
