import React from "react"

// Components
import * as styles from "./index.module.scss"
import { QuoteLarge } from "../../../components/icons"

const Quote = ({ data: { author, remarks, logo } }) => (
  <div className={`${styles.quoteContainer} mt-9 py-5 lg:py-3 px-4 xl:px-0`}>
    <div
      className={` max-w-6xl mx-auto flex flex-col lg:flex-row items-center`}
    >
      <div className="mb-4 lg:mb-0 mr-0 lg:mr-20">
        <QuoteLarge className={`${styles.quoteIcon} lg:w-3/4 mx-auto`} />
      </div>
      <div className="flex-1">
        <blockquote
          className={`${styles.blockquote} mb-5 text-center lg:text-left`}
        >
          {remarks.remarks}
        </blockquote>
        <div className="flex flex-col items-center lg:items-start lg:flex-row lg:justify-between">
          <p className="mb-3 lg:mb-0 text-center lg:text-left">{author}</p>
          <img
            src={logo.file.url}
            alt={logo.title}
            loading="lazy"
            className={`${styles.quoteImage} w-1/2 md:w-4/12`}
          />
        </div>
      </div>
    </div>
  </div>
)

export default Quote
