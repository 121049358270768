import React from "react"
// import Img from "gatsby-image"

// Components
import { VerifiedBlue } from "../../components/icons"

const About = ({ data: { heading, details, listItems } }) => (
  <div>
    <div className="max-w-6xl mx-auto mb-8 lg:mb-0 pt-8 md:pt-14 px-4 xl:px-0 flex flex-col lg:flex-row gap-y-4 items-start">
      <div className="lg:w-3/5">
        <h3 className="mb-4 text-center lg:text-left">{heading}</h3>
        <p className="lg:w-4/5 text-center lg:text-left">{details}</p>
      </div>
      <div className="w-full lg:w-2/5 mt-4 md:mt-0">
        {listItems.map((item, index) => (
          <div
            key={index}
            className="flex md:gap-x-6 mb-5 items-centers justify-start"
          >
            <div className="w-6 mt-1 mr-3 ">
              <VerifiedBlue color="#fff" />
            </div>
            <h2>{item}</h2>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default About
