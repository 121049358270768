import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import { Website } from "../../../components/icons"

import * as styles from "./index.module.scss"

const FeaturedPartners = ({
  data: { featuredPartnersTitle, featuredPartnersImage, featuredPartnersList },
}) => {
  const image = getImage(featuredPartnersImage)
  return (
    <div className="px-4 mb-8 md:px-8 xl:px-0 lg:mb-0">
      <div className="max-w-6xl mx-auto mt-4 lg:mt-16">
        <h3 className="mb-5 text-center lg:mb-12 lg:text-left">
          {featuredPartnersTitle}
        </h3>
        <div className="flex flex-col lg:flex-row">
          <div className="flex items-center justify-center flex-1 lg:w-2/5 lg:mr-20">
            <GatsbyImage
              image={image}
              alt={featuredPartnersImage.title}
              loading="lazy"
              className="w-1/3 lg:w-10/12"
            />
          </div>
          <div className="flex flex-col w-full lg:w-3/5 lg:mb-16">
            {featuredPartnersList.map(({ id, image, website, websiteUrl }) => {
              const partnerImg = getImage(image)
              return (
                <div
                  key={id}
                  className={`${styles.partnerRow} flex flex-col lg:flex-row items-center pb-2 lg:py-4 `}
                >
                  <div className={`${styles.partnerImage} flex items-center`}>
                    <GatsbyImage
                      image={partnerImg}
                      alt={image.title}
                      loading="lazy"
                      className="mx-auto"
                    />
                  </div>
                  <div className="lg:pl-28">
                    <a
                      href={websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="flex items-center">
                        <Website className="w-8 mr-2 icon md:mr-3" />
                        <span className="hoverLink">
                          <div className="z-10">{website}</div>
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedPartners
